import NextLink from 'next/link'
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa'

import { Flex, Link } from '@chakra-ui/react'

import { DarkLogo } from '../icons/DarkLogo'

const Footer = () => {
  type LinkType = {
    href: string
    label: string
  }

  const links: LinkType[] = [
    { href: 'mailto:hello@staytuned.co', label: 'hello@staytuned.co' },
  ]

  const navigationColumn1 = [
    { href: '/our-tech', label: 'Our tech' },
    { href: '/locations', label: 'Locations' },
    // { href: '/press', label: 'Press' },
    // { href: '/faq', label: 'FAQ' },
    // { href: '/privacy', label: 'Privacy' },
  ]

  const navigationColumn2 = [
    { href: '/tune-studio', label: 'TUNE Studio' },
    { href: '/products', label: 'TUNE for Home' },
    { href: '/tune-work', label: 'TUNE for Work' },
  ]

  return (
    <div className="px-8 lg:px-16 py-24 items-center">
      <div className="flex flex-col gap-y-10 lg:flex-row flex-gap justify-between">
        <div className="w-full lg:w-[35%]">
          <DarkLogo className="mb-12" />

          <div style={{ border: '1px solid #CC724F', width: '100%' }}>
            <div
              dangerouslySetInnerHTML={{
                __html: `<!-- Begin Mailchimp Signup Form -->
            <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">
            <style type="text/css">
              input:focus {
                outline: none;
              }
              #mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif;  width:100%;}
              /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
                 We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
            </style>
            <div id="mc_embed_signup">
            <form action="https://studio.us14.list-manage.com/subscribe/post?u=18040610a7d54259d836b549d&amp;id=549a462876" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                <div id="mc_embed_signup_scroll">
            <div class="mc-field-group">
              <label for="mce-EMAIL" style="font-family:Greycliff CF;">Email Address  <span class="asterisk">*</span>
            </label>
              <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
            </div>
              <div id="mce-responses" class="clear">
                <div class="response" id="mce-error-response" style="display:none"></div>
                <div class="response" id="mce-success-response" style="display:none"></div>
              </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_18040610a7d54259d836b549d_549a462876" tabindex="-1" value=""></div>
                <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button" style="background-color:#cc724f; font-weight: 700; border-radius:0"></div>
                </div>
            </form>
            </div>
            <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
            <!--End mc_embed_signup-->`,
              }}
            />
          </div>
        </div>
        <div className="flex flex-col w-full md:w-[20%] mt-2">
          <p className="text-black text-serif italic min-w-[224px] mb-5">
            Navigation
          </p>
          <div className="grid grid-cols-2 gap-x-20">
            <div className="grid auto-rows-min gap-y-3.5 min-w-[110px] whitespace-nowrap">
              {navigationColumn1.map((link) => (
                <NextLink href={link.href} key={link.href}>
                  <a className="text-charcoal font-helv capitalize font-medium">
                    {link.label}
                  </a>
                </NextLink>
              ))}
            </div>
            <div className="grid auto-rows-min gap-y-3.5 min-w-[110px] whitespace-nowrap">
              {navigationColumn2.map((link) => (
                <NextLink href={link.href} key={link.href}>
                  <a className="text-charcoal font-helv font-medium">
                    {link.label}
                  </a>
                </NextLink>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full md:w-[20%] mt-2">
          <p className="text-black text-serif italic min-w-[224px] mb-5">
            For Press, Partnerships & Inquiries
          </p>
          <div className="flex">
            {links.map((link) => (
              <NextLink href={link.href} key={link.label}>
                <a className="font-helv text-charcoal uppercase font-medium">
                  {link.label}
                </a>
              </NextLink>
            ))}
          </div>

          <Flex justify="space-between" w={['40%', '40%', '100%']} mt={[4]}>
            <Link
              color="#45221D"
              _hover={{
                color: '#CC724F',
              }}
              href="https://facebook.com/thetunelife"
              isExternal
            >
              <FaFacebookF size={24} />
            </Link>
            <Link
              color="#45221D"
              _hover={{
                color: '#CC724F',
              }}
              href="https://instagram.com/thetunelife"
              isExternal
            >
              <FaInstagram size={24} />
            </Link>
            <Link
              color="#45221D"
              _hover={{
                color: '#CC724F',
              }}
              href="https://twitter.com/thetunelife"
              isExternal
            >
              <FaTwitter size={24} />
            </Link>
          </Flex>
        </div>
      </div>
      <p className="mt-8 lg:mt-20 initial font-helv text-sm text-charcoal">
        © 2023 Conscious Patterns
      </p>
    </div>
  )
}

export default Footer
