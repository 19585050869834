import { ReactNode, useContext, useEffect, useState } from 'react'

import AuthContext from '../stores/AuthContext'
import Footer from './Footer'
import Navbar from './Navbar'
import { Banner } from './Banner'
import { useRouter } from 'next/router'

export type LayoutProps = {
  children?: ReactNode
  relative?: boolean
  fixed?: boolean
  hideFooter?: boolean
  hideMenu?: boolean
  bgClass?: string
}

const Layout = ({
  children,
  relative,
  fixed,
  hideFooter = false,
  hideMenu = false,
  bgClass,
}: LayoutProps) => {
  const { state, setState } = useContext(AuthContext)
  const [maintenanceModeActive, setMaintenanceModeActive] = useState(false)
  const router = useRouter()
  const currentPath = router.pathname
  const [isOnShowWarningPath] = useState(currentPath === '/start-session')

  const handleToolTip = () => {
    setState({ isTooltipOpen: false })
  }

  const bg = bgClass ? bgClass : 'bg-white'

  useEffect(() => {
    async function checkMode() {
      const response = await fetch('/api/getMode')
      const { maintenanceModeActive } = await response.json()
      const isMaintenanceModeActive = maintenanceModeActive === 'true'
      setMaintenanceModeActive(isMaintenanceModeActive)
    }
    checkMode()
  }, [])

  return (
    <div className={`relative flex flex-col ${bg}`}>
      {state?.isTooltipOpen && (
        <div
          className="fixed z-10 w-full h-full backdrop-brightness-50 t-0 l-0"
          onClick={handleToolTip}
        />
      )}
      <Navbar />
      {maintenanceModeActive && isOnShowWarningPath && (
        <Banner message="The TUNE services are undergoing maintenance. You may encounter issues using the TUNE Wellness Bed during this time." />
      )}
      {children}
      {!hideFooter && <Footer />}
    </div>
  )
}

export default Layout
